<template>
  <div class="main-menu">
    <ul>
      <li>
        <a class="btn" @click.prevent="goToStep(1)" :title="1 | stepTitle">
          <span v-if="!viewOptions.mainMenuOpen">1</span>
          <span v-else>{{ 1 | stepTitle }}</span>
        </a>
      </li>
      <li>
        <a class="btn" @click.prevent="goToStep(2)" :class="{ disabled: !isFirstStepCompleted }" :title="2 | stepTitle">
          <span v-if="!viewOptions.mainMenuOpen">2</span>
          <span v-else>{{ 2 | stepTitle }}</span>
        </a>
      </li>
      <li>
        <a class="btn" @click.prevent="goToStep(3)" :class="{ disabled: !isFirstStepCompleted }" :title="3 | stepTitle">
          <span v-if="!viewOptions.mainMenuOpen">3</span>
          <span v-else>{{ 3 | stepTitle }}</span>
        </a>
      </li>
      <li>
        <a class="btn" @click.prevent="goToStep(4)" :class="{ disabled: !isFirstStepCompleted }" :title="4 | stepTitle">
          <span v-if="!viewOptions.mainMenuOpen">4</span>
          <span v-else>{{ 4 | stepTitle }}</span>
        </a>
      </li>
      <li>
        <a class="btn" @click.prevent="goToStep(5)" :class="{ disabled: !isFirstStepCompleted }" :title="5 | stepTitle">
          <span v-if="!viewOptions.mainMenuOpen">5</span>
          <span v-else>{{ 5 | stepTitle }}</span>
        </a>
      </li>
      <li>
        <router-link class="btn" :to="{ name: 'glossary' }" title="Glossario">
          <span v-if="!viewOptions.mainMenuOpen">G</span>
          <span v-else>Glossario</span>
        </router-link>
      </li>
      <li>
        <router-link class="btn" :to="{ name: 'laws' }" title="Sintesi legislativa">
          <span v-if="!viewOptions.mainMenuOpen">L</span>
          <span v-else>Sintesi legislativa</span>
        </router-link>
      </li>
      <li class="action reset">
        <a class="btn" @click.prevent="$emit('reset-survey')" title="Nuovo questionario">
          <span v-if="!viewOptions.mainMenuOpen">N</span>
          <span v-else>Nuovo questionario</span>
        </a>
      </li>
      <li class="action save">
        <a class="btn" @click.prevent="$emit('save-survey-file')" title="Salva questionario">
          <span v-if="!viewOptions.mainMenuOpen">S</span>
          <span v-else>Salva questionario</span>
        </a>
      </li>
      <li class="action open">
        <a class="btn" @click.prevent="$emit('open-survey-file')" title="Apri questionario">
          <span v-if="!viewOptions.mainMenuOpen">A</span>
          <span v-else>Apri questionario</span>
        </a>
      </li>
      <li class="action send">
        <a class="btn" @click.prevent="$emit('send-survey-file')" :class="{ disabled: !isFirstStepCompleted }" title="Ottieni responso">
          <span v-if="!viewOptions.mainMenuOpen">R</span>
          <span v-else>Ottieni responso</span>
        </a>
      </li>
      <li v-if="$oauth2.me" class="action logout">
        <a class="btn" @click.prevent="$emit('logout')" title="Logout">
          <span v-if="!viewOptions.mainMenuOpen">O</span>
          <span v-else>Logout</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import goToStepMixin from '@/mixins/goToStepMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

export default {
  mixins: [goToStepMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  props: {
    viewOptions: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('survey', {
      isFirstStepCompleted: 'isFirstStepCompleted',
    }),
  },
};

</script>

<style lang="scss">
</style>
